import React, { useCallback } from "react"
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';

export function OfferPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToNextPage = useCallback(() => {
    navigate('/cell-number');
  }, [navigate]);

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.offerPage.title')}</p>
        </div>
      </div>
      <Button
        type="button"
        onClick={goToNextPage}
        text={t('translation.buttons.continue')}
      />
    </>
  )
}
